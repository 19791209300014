import { Route, Routes } from 'react-router-dom';

import AdminSettingsManagementPage from '../AdminSettings/AdminSettingsManagementPage';
import ArchivePage from '../ArchivePage';
import FeedbackManagementPage from '../FeedbackManagementPage';
import HomePage from '../HomePage';
import LoginPage from '../LoginPage';
import PracticePage from '../PracticePage';
import PrivacyPage from '../PrivacyPage';
import ReportsPage from '../ReportsPage';
import SentenceManagementPage from '../SentenceManagementPage';
import SentenceSetsPage from '../SentenceSetsPage';
import SetManagementPage from '../SetManagementPage';
import SettingsPage from '../SettingsPage';
import SynonymManagementPage from '../SynonymManagementPage';
import TOUPage from '../TOUPage';
import TranslationManagementPage from '../TranslationManagementPage';
import VoiceManagementPage from '../VoiceManagementPage';

const PublicRoutes = () => (
  <Routes>
    <Route path="/appsettings" element={<AdminSettingsManagementPage />} />
    <Route path="/manage-sets" element={<SetManagementPage />} />
    <Route path="/manage-sentence/:id?" element={<SentenceManagementPage />} />
    <Route path="/manage-translation/:id?" element={<TranslationManagementPage />} />
    <Route path="/manage-voice/:id?" element={<VoiceManagementPage />} />
    <Route path="/practice/:id" element={<PracticePage />} />
    <Route path="/synonyms" element={<SynonymManagementPage />} />
    <Route path="/feedback" element={<FeedbackManagementPage />} />
    <Route path="/settings" element={<SettingsPage />} />
    <Route path="/archive" element={<ArchivePage />} />
    <Route path="/sets" element={<SentenceSetsPage />} />
    <Route path="/reports" element={<ReportsPage />} />

    <Route path="/login" element={<LoginPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/tou" element={<TOUPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="" element={<HomePage />} />
    <Route path="*" element={<p>Path not resolved</p>} />
  </Routes>
);

export default PublicRoutes;

import { Helmet } from 'react-helmet-async';

import PracticeSync from '../components/PracticeSync';

const ArchivePage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Archive</title>
      </Helmet>

      <PracticeSync />
    </div>
  );
};

export default ArchivePage;

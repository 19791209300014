import 'react-markdown-editor-lite/lib/index.css';

import MarkdownIt from 'markdown-it';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Nav } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { FaCheck, FaPlus, FaRegEdit, FaVolumeUp } from 'react-icons/fa';
import ReactMarkdownEditorLite from 'react-markdown-editor-lite';
import { Link } from 'react-router-dom';

import {
  SentenceSet,
  SentenceSetUpsertInput,
  useDeleteSentenceSetMutation,
  useUpdateSentenceSetOrderMutation,
  useUpsertSentenceSetMutation,
} from '../graphql/server-graphql-schema';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import { StrictModeDroppable } from '../libs/StrictModeDroppable';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ToastNotification from './ToastNotification';

const SetForm: React.FC = () => {
  const initForm: {
    id?: string;
    name: string;
    notes: string;
    lang: string;
    type: string;
    isPublic: boolean;
    lesson: string;
    meta: string; // Meta as a JSON string
  } = {
    id: undefined,
    name: '',
    notes: '',
    lang: 'es-MX',
    type: 'sentence',
    isPublic: false,
    lesson: '',
    meta: '{}', // Initialize with an empty JSON object
  };

  const { sentenceSets, error, refetch, loading } = useSentenceSetSets();
  const mdParser = new MarkdownIt();

  const [deleteSentenceSetMutation] = useDeleteSentenceSetMutation();
  const [updateSentenceSetOrderMutation, { loading: loadingUpdateOrder }] = useUpdateSentenceSetOrderMutation();
  const [upsertSentenceSetMutation, { loading: loadingSave }] = useUpsertSentenceSetMutation();

  const [sentenceSetsOrdered, setSentenceSetsOrdered] = useState<SentenceSet[]>([]);
  const [needSavingOrder, setNeedSavingOrder] = useState(false);
  const [formData, setFormData] = useState(initForm);
  const [isEditMode, setIsEditMode] = useState<string | null>(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [setToDelete, setSetToDelete] = useState<SentenceSet | null>(null);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState<'success' | 'error'>('success');

  useEffect(() => {
    if (sentenceSets && sentenceSets.length !== sentenceSetsOrdered.length) {
      setSentenceSetsOrdered(sentenceSets);
    } else if (sentenceSets && JSON.stringify(sentenceSets) !== JSON.stringify(sentenceSetsOrdered)) {
      setSentenceSetsOrdered(sentenceSets);
    }
  }, [sentenceSets, sentenceSetsOrdered]);

  const refreshSentenceSets = async () => {
    const { data } = await refetch();
    if (data) {
      setSentenceSetsOrdered(data.getAllSentenceSets);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(sentenceSetsOrdered);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSentenceSetsOrdered(items);
    setNeedSavingOrder(true);
  };

  const handleDeleteSentenceSet = async (id: string) => {
    const setToDelete = sentenceSets.find((set) => set.id === id);
    if (setToDelete) {
      setSetToDelete(setToDelete);
      setShowDeleteModal(true);
    }
  };

  const confirmDelete = async () => {
    if (setToDelete) {
      try {
        await deleteSentenceSetMutation({
          fetchPolicy: 'network-only',
          variables: { id: setToDelete.id },
        });

        setShowDeleteModal(false);
        setSetToDelete(null);
        setToastMessage('Sentence set deleted successfully.');
        setToastVariant('success');
        setShowToast(true);
        setIsFormVisible(false);
        await refreshSentenceSets();
      } catch (err) {
        setToastMessage('Error deleting sentence set.');
        setToastVariant('error');
        setShowToast(true);
        console.error('Error deleting set:', err);
      }
    }
  };

  const handleEditSentenceSet = (setId: string) => {
    const setToEdit = sentenceSets.find((set) => set.id === setId);
    if (setToEdit) {
      setFormData({
        id: setToEdit.id,
        name: setToEdit.name,
        notes: setToEdit.notes || '',
        lang: setToEdit.lang!,
        type: setToEdit.type,
        isPublic: setToEdit.isPublic || false,
        lesson: setToEdit.meta?.lessons[0]?.content || '',
        meta: JSON.stringify(
          { ...setToEdit.meta, lessons: undefined }, // Exclude lessons from editable meta
          null,
          2,
        ),
      });
      setIsEditMode(setId);
      setIsFormVisible(true);
    }
  };

  const handleSaveButtonPress = async () => {
    try {
      let parsedMeta;
      try {
        parsedMeta = JSON.parse(formData.meta);
      } catch (error: any) {
        setToastMessage('Invalid JSON format in Meta field.' + error.message);
        setToastVariant('error');
        setShowToast(true);
        return;
      }

      const { lesson, ...restOfFormData } = formData;

      // Combine lessons back into meta
      const finalMeta = { ...parsedMeta, lessons: [{ content: lesson }] };

      if (needSavingOrder) {
        const ids = sentenceSetsOrdered.map((s) => s.id);
        await updateSentenceSetOrderMutation({
          variables: { ids },
        });
        setNeedSavingOrder(false);
      }

      if (restOfFormData.name !== '') {
        const input: SentenceSetUpsertInput = {
          schema: 1.0,
          ...restOfFormData,
          meta: finalMeta, // Use the final meta with lessons combined
        };

        await upsertSentenceSetMutation({
          fetchPolicy: 'network-only',
          variables: {
            input,
          },
        });
        setFormData(initForm);
        setIsEditMode(null);
        setIsFormVisible(false);
        setToastMessage('Sentence set saved successfully.');
        setToastVariant('success');
        setShowToast(true);
        await refreshSentenceSets();
      }
    } catch (error) {
      setToastMessage('Error saving sentence set.');
      setToastVariant('error');
      setShowToast(true);
      console.error('Error creating SentenceSet:', error);
    }
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
      const { checked } = e.target; // Safely access checked only for checkboxes
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleLessonChange = ({ text }: { html: string; text: string }) => {
    setFormData((prevData) => ({ ...prevData, lesson: text }));
  };

  const handleAddButtonPress = () => {
    setFormData(initForm);
    setIsEditMode(null);
    setIsFormVisible(true);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-end mb-3">
        <Button variant="secondary" onClick={handleAddButtonPress}>
          <FaPlus /> Add Set
        </Button>
      </div>

      {isFormVisible && (
        <Form className="border rounded bg-body-tertiary p-3">
          <Form.Group as={Row} controlId="formName" className="mb-2">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="4">
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                value={formData.name}
                onChange={handleFormChange}
                placeholder=""
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formType" className="mb-2">
            <Form.Label column sm="2">
              Type
            </Form.Label>
            <Col sm="4">
              <Form.Control as="select" name="type" value={formData.type} onChange={handleFormChange}>
                <option value="sentence">Sentence</option>
                <option value="word">Word</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formLang" className="mb-2">
            <Form.Label column sm="2">
              Language
            </Form.Label>
            <Col sm="4">
              <Form.Control type="text" readOnly name="lang" value={formData.lang} placeholder="" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formNotes" className="mb-2">
            <Form.Label column sm="2">
              Notes
            </Form.Label>
            <Col sm="10">
              <Form.Control
                rows={3}
                as="textarea"
                name="notes"
                value={formData.notes}
                onChange={handleFormChange}
                placeholder=""
              />
            </Col>
          </Form.Group>
          {/* Lesson markdown editor */}
          <Form.Group as={Row} controlId="formLesson" className="mb-2">
            <Form.Label column sm="2">
              Lesson
            </Form.Label>
            <Col sm="10">
              <ReactMarkdownEditorLite
                id="formLesson"
                value={formData.lesson}
                onChange={handleLessonChange}
                renderHTML={(text) => mdParser.render(text)}
              />
            </Col>
          </Form.Group>
          {/* Meta JSON input */}
          <Form.Group as={Row} controlId="formMeta" className="mb-2">
            <Form.Label column sm="2">
              Meta (JSON)
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                name="meta"
                rows={10}
                value={formData.meta}
                onChange={handleFormChange}
                placeholder='Enter valid JSON, e.g. {"key": "value"}'
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPublic" className="mb-2">
            <Form.Label column sm="2">
              Public Set
            </Form.Label>
            <Col sm="10" className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                name="isPublic"
                label="Public"
                checked={formData.isPublic}
                onChange={handleFormChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
            <Col sm={12} className="d-flex justify-content-between">
              <Button
                variant="primary"
                type="button"
                onClick={handleSaveButtonPress}
                disabled={loadingSave || loadingUpdateOrder}
              >
                {loadingSave || loadingUpdateOrder ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Saving...
                  </>
                ) : (
                  'Save'
                )}
              </Button>
              {isEditMode && (
                <Button variant="danger" onClick={() => handleDeleteSentenceSet(isEditMode)}>
                  Delete
                </Button>
              )}
              <Button variant="secondary" type="button" onClick={() => setIsFormVisible(false)}>
                Cancel
              </Button>
            </Col>
          </Form.Group>
        </Form>
      )}

      <div className="mt-4">
        {error && <p>Error: {error.message}</p>}
        {loading && <div>Loading sets...</div>}
        {sentenceSetsOrdered.length > 0 ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId="droppable">
                {(provided) => (
                  <table
                    className="table table-bordered table-sm table-striped"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Public</th>
                        <th>Count / POS / Hints / Voice</th>
                        <th className="col-1">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {sentenceSetsOrdered.map((set, index) => (
                        <Draggable key={set.id} draggableId={set.id} index={index}>
                          {(provided) => (
                            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <td className="align-middle">{index + 1}</td>
                              <td className="align-middle">{set.name}</td>
                              <td className="align-middle text-center">{set.type}</td>
                              <td className="align-middle text-center">{set.isPublic ? <FaCheck /> : '-'}</td>
                              <td className="align-middle text-center">
                                {set.sentences?.length}
                                {' / '}
                                {set.sentences?.filter((sentence) => sentence.meta.pos !== undefined).length}
                                {' / '}
                                {set.sentences?.filter((sentence) => sentence.meta.hints !== undefined).length}
                                {' / '}
                                {set.sentences?.filter((sentence) => sentence.meta.voiceGenerated !== undefined).length}
                              </td>
                              <td className="align-middle col-3">
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                  <Nav.Link as={Link} to={`/manage-sentence/${set.id}`}>
                                    <FaPlus />
                                  </Nav.Link>

                                  <Nav.Link as={Link} to={`/manage-translation/${set.id}`}>
                                    Meta
                                  </Nav.Link>

                                  <Nav.Link as={Link} to={`/manage-voice/${set.id}`}>
                                    <FaVolumeUp />
                                  </Nav.Link>

                                  <Button variant="link" onClick={() => handleEditSentenceSet(set.id)}>
                                    <FaRegEdit />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </>
        ) : (
          <div>No Sets are available.</div>
        )}
      </div>

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        itemName={setToDelete?.name || ''}
      />

      <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} variant={toastVariant} />
    </div>
  );
};

export default SetForm;

import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import Navbar from 'react-bootstrap/esm/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../store/store';

const NavBarComponent: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleLinkClick = () => {
    setIsExpanded(false);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="mt-3 mb-4 bg-body-tertiary" expanded={isExpanded}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo128.png`}
            alt="Lengua.App"
            width="auto"
            height="80"
            className="d-none d-md-inline-block align-middle mt-n2 mb-n4 mb-md-n4 pe-md-5"
          />
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo64.png`}
            alt="Lengua.App"
            width="auto"
            height="64"
            className="d-inline-block d-md-none align-middle mt-n2 mb-n4 mb-md-n4 pe-md-5"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsExpanded(!isExpanded)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto mt-3 mt-md-0">
            <Nav.Link as={Link} onClick={handleLinkClick} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} onClick={handleLinkClick} to="/privacy">
              Privacy
            </Nav.Link>
            <Nav.Link as={Link} onClick={handleLinkClick} to="/tou">
              Terms of Service
            </Nav.Link>
            {auth.role === 'admin' ? (
              <>
                <Nav.Link as={Link} onClick={handleLinkClick} to="/synonyms">
                  Synonyms
                </Nav.Link>
                <Nav.Link as={Link} onClick={handleLinkClick} to="/feedback">
                  Feedbacks
                </Nav.Link>
                <Nav.Link as={Link} onClick={handleLinkClick} to="/sets">
                  Practice
                </Nav.Link>
                <Nav.Link as={Link} onClick={handleLinkClick} to="/manage-sets">
                  Sets
                </Nav.Link>
                <Nav.Link as={Link} onClick={handleLinkClick} to="/appsettings">
                  Settings
                </Nav.Link>
                <Nav.Link as={Link} onClick={handleLinkClick} to="/reports">
                  Reports
                </Nav.Link>
              </>
            ) : null}
          </Nav>
          <Nav>
            {auth.email === '' ? (
              <Nav.Link as={Link} to="/login" onClick={handleLinkClick} className="text-decoration-none ml-auto">
                Login
              </Nav.Link>
            ) : (
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={solid('user')} className="me-1" style={{ color: 'gray' }} />
                    Account
                  </>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/settings" onClick={handleLinkClick}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/archive" onClick={handleLinkClick}>
                  History
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarComponent;

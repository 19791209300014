import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FaSyncAlt } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';

import { normalizeText } from '@ddehghan/lengua-shared';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PRACTICE_SCHEMA } from '../constants';
import { UploadPracticeInput, usePracticesQuery, useUploadPracticeMutation } from '../graphql/server-graphql-schema';
import { addPracticesSynced, deletePractices, Practice } from '../store/coreSlice';
import { RootState } from '../store/store';

const PracticeSync: React.FC = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth.auth);
  const practices = useSelector((state: RootState) => state.core.practices);
  const practicesSynced = useSelector((state: RootState) => state.core.practicesSynced);

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [uploadPracticeMutation, { loading: loadingUpload }] = useUploadPracticeMutation();

  const {
    loading,
    // error,
    data: dataQuery,
  } = usePracticesQuery({
    fetchPolicy: 'network-only',
    variables: {},
    skip: auth.email === '' || !shouldRefetch,
  });

  useEffect(() => {
    if (!loading && dataQuery && shouldRefetch) {
      const practices = dataQuery.practices.map((p: any) => ({
        ...p.data,
        schema: p.schema,
        id: p.id,
      }));

      dispatch(addPracticesSynced(practices));
      setShouldRefetch(false);
    }
  }, [loading, dataQuery, dispatch, shouldRefetch]);

  const handleSync = async () => {
    if (practices.length === 0) {
      setShouldRefetch(true);
      return;
    }

    try {
      const toUpload: UploadPracticeInput[] = practices.map((practice) => ({
        schema: PRACTICE_SCHEMA,
        date: practice.date,
        data: practice,
      }));

      const response = await uploadPracticeMutation({
        fetchPolicy: 'network-only',
        variables: {
          input: toUpload,
        },
      });

      if (response?.data?.uploadPractice) {
        dispatch(deletePractices());
      }

      setShouldRefetch(true);
    } catch (error) {
      console.error('Error creating sentence:', error);
    }
  };

  const handleDeletePractice = async (practiceId: string) => {
    try {
      dispatch(deletePractices(practiceId));
    } catch (error) {
      console.error('Error deleting:', error);
    }
  };

  return (
    <div className="row mt-5">
      <Helmet>
        <title>Practice</title>
      </Helmet>

      {/* <h4>Answer History:</h4> */}
      <div className="d-flex">
        <div className="mt-3">Synchronize your responses between desktop and mobile with 'Save to Cloud'</div>
        <Button variant="primary" className="ms-auto" onClick={handleSync}>
          {loadingUpload ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Uploading...
            </>
          ) : (
            <span>
              {' '}
              <FaSyncAlt className="me-2" />
              Save to Cloud
            </span>
          )}
        </Button>
      </div>

      <hr className="my-5 mx-auto" style={{ width: '70%' }} />

      <div className="col-12">
        <h4>Recent Answers:</h4>
      </div>

      <div className="col-12">
        <div>
          {practices.length > 0 ? (
            <table className="table table-sm table-striped table-bordered rounded">
              <thead>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={light('user')} className="me-1" /> My Answer
                  </th>
                  <th>
                    <FontAwesomeIcon icon={solid('user-graduate')} className="me-1" /> Correct Answer
                  </th>
                  <th className="text-center">Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {practices.map((p: Practice) => (
                  <tr key={p.id}>
                    <td className="align-middle">{p.text}</td>
                    <td className="align-middle">{p.sentence.text}</td>
                    <td className="align-middle text-center">{moment.unix(p.date).fromNow()}</td>
                    <td className="align-middle">
                      <Button variant="link" onClick={() => handleDeletePractice(p.id)}>
                        <FaTrashCan style={{ color: 'red' }} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="mt-3">You haven't practiced yet. Dive in and explore some exercises!</div>
          )}
        </div>
      </div>

      <hr className="my-5 mx-auto" style={{ width: '70%' }} />

      <div className="col-12">
        <h4>Saved to Cloud:</h4>
      </div>

      {practicesSynced.length > 0 ? (
        <div className="d-flex justify-content-center mt-4">
          <table className="table table-sm table-striped table-bordered rounded">
            <thead>
              <tr>
                <th>
                  <FontAwesomeIcon icon={light('user')} className="me-1" /> My Answer
                </th>
                <th>
                  <FontAwesomeIcon icon={solid('user-graduate')} className="me-1" /> Correction
                </th>
                <th className="text-center">Date</th>
              </tr>
            </thead>
            <tbody>
              {practicesSynced.map((p: Practice) => (
                <tr key={p.id}>
                  <td className="align-middle">
                    {normalizeText(p.text) === normalizeText(p.sentence.text) ? (
                      <>
                        <FontAwesomeIcon icon={solid('check')} style={{ color: 'green' }} className="me-1" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={solid('xmark')} style={{ color: 'red' }} className="me-1" />
                      </>
                    )}
                    {p.text}
                  </td>
                  <td className="align-middle">{p.sentence.text}</td>
                  <td className="align-middle text-center">{moment.unix(p.date).fromNow()}</td>
                  {/*<td className="align-middle">
                    <Button variant="link" onClick={() => handleDeletePractice(p.id)}>
                    <FaTrashCan style={{ color: 'red' }} />
                  </Button> 
                  </td>*/}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center mt-4">
          You have not archived any of your answers yet. Archived answers sync between your computer and your mobile phone.
        </div>
      )}
    </div>
  );
};

export default PracticeSync;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SETS_ASSETS_URL = exports.CONTENT_URL = exports.API_URL = exports.AUTH_CLIENT_IDS = exports.API_VERSION = void 0;
exports.API_VERSION = '1';
const AUTH_CLIENT_IDS = {
    /* cspell: disable-next-line */
    webClientId: '91254831426-mrs7gjlip8ope6er0pfu0joeha1c5mku.apps.googleusercontent.com',
    /* cspell: disable-next-line */
    androidClientId: '91254831426-nd5h326o567nfeufdncq82npdcmff2f1.apps.googleusercontent.com',
    /* cspell: disable-next-line */
    iosClientId: '91254831426-5jhpo1sd5fjr22bf94fes5qkku9jeogc.apps.googleusercontent.com',
};
exports.AUTH_CLIENT_IDS = AUTH_CLIENT_IDS;
// const SERVER_ENV = 'stage';
// const BASE_URL = 'https://api-stage.lengua.app'; // STAGE
const SERVER_ENV = 'prod';
const BASE_URL = 'https://api.lengua.app'; // PROD
// const BASE_URL = 'http://192.168.1.79:4000'; // Maman
// const BASE_URL = 'http://192.168.86.44:4000'; //mini M2   BEN HOME
// const BASE_URL = 'http://192.168.0.100:4000'; // TRAVEL
const API_URL = `${BASE_URL}/graphql`;
exports.API_URL = API_URL;
const CONTENT_URL = `https://storage.googleapis.com/lengua.app/${SERVER_ENV}/content`;
exports.CONTENT_URL = CONTENT_URL;
const SETS_ASSETS_URL = `https://storage.googleapis.com/lengua.app/${SERVER_ENV}/sets`;
exports.SETS_ASSETS_URL = SETS_ASSETS_URL;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import { GetUserReportsQuery, useGetUserReportsQuery, UserSortInput } from '../graphql/server-graphql-schema';

// Sorting options
const sortOptions = {
  topUsers: { field: 'reportCount', direction: 'desc' } as UserSortInput,
  latestSignUps: { field: 'createdDate', direction: 'desc' } as UserSortInput,
};

const ReportsPage: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(20);

  // Default sorting to "latestSignUps"
  const [sortOption, setSortOption] = useState<UserSortInput>(sortOptions.latestSignUps);

  // Local state for keeping track of previous data to avoid flashing
  const [userData, setUserData] = useState<GetUserReportsQuery['userReports']['users']>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  // Fetch reports query
  const { data, loading, error, refetch } = useGetUserReportsQuery({
    variables: { page, limit, sort: sortOption },
    fetchPolicy: 'cache-and-network',
  });

  // Handle page click event for pagination
  const handlePageClick = (selectedItem: { selected: number }) => {
    const newPage = selectedItem.selected + 1;
    setPage(newPage);
  };

  // Change sorting filter and refetch data
  const handleSortChange = (type: 'topUsers' | 'latestSignUps') => {
    const newSort = type === 'topUsers' ? sortOptions.topUsers : sortOptions.latestSignUps;
    setSortOption(newSort);
    setPage(1); // Reset to first page after changing sorting
    void refetch({ page: 1, limit, sort: newSort });
  };

  // Update the local state when new data arrives without causing flashing
  useEffect(() => {
    if (data?.userReports?.users) {
      setUserData(data.userReports.users);
      setTotalUsers(data.userReports.total || 0);
    }
  }, [data]);

  // Display loading or error state
  if (error) return <p>Error loading reports: {error.message}</p>;

  return (
    <Container className="my-4">
      <Row className="my-5" style={{ height: '100px' }}>
        <Col className="d-flex justify-content-between">
          <h2>Total Users: {totalUsers}</h2>
          <div>
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalUsers / limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick} // Correct pagination handler
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
            />
          </div>

          <div className="d-flex" style={{ width: '200px' }}>
            <Dropdown>
              <Dropdown.Toggle variant="primary">Filter Reports</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSortChange('topUsers')}>Top users by Reports</Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange('latestSignUps')}>Latest sign ups</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {loading && (
              <span className="ms-3">
                <Spinner animation="border" role="status" />
              </span>
            )}
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col>
          <Table striped bordered hover className="table-sm table-striped w-100">
            <thead>
              <tr className="text-center">
                <th>Name</th>
                <th>Email</th>
                <th>Avatar</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Report Count</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user) => (
                <tr key={user.id}>
                  <td className="align-middle">{user.name || 'Unknown'}</td>
                  <td className="align-middle">{user.email || 'No Email'}</td>
                  <td className="align-middle text-center" style={{ height: '50px' }}>
                    {user.avatar ? (
                      <img
                        src={user.avatar}
                        alt={user.name || 'No Name Available'}
                        width={50}
                        height={50}
                        onError={(e) => {
                          e.currentTarget.src = '/fallback-avatar.png';
                          e.currentTarget.alt = 'Avatar Not Available';
                        }}
                      />
                    ) : (
                      'No Avatar'
                    )}
                  </td>
                  <td className="align-middle text-center">
                    {user.createdDate ? moment(user.createdDate).format('YYYY-MM-DD HH:mm') : 'No Date'}
                  </td>
                  <td className="align-middle text-center">
                    {user.updatedDate ? moment(user.updatedDate).format('YYYY-MM-DD HH:mm') : 'No Date'}
                  </td>
                  <td className="align-middle text-center">{user.reportCount ?? '0'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ReportsPage;
